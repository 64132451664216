<script>
import Layout from '@/views/layouts/main'
import { onMounted, ref } from 'vue'
import FileUploader from '@/components/file-uploader'
import { mask } from 'vue-the-mask'
import { useRoute, useRouter } from 'vue-router'
import { createTerm, showTerm, updateTerm } from './api'
import Notify from '@/notify'

const statusMap = {
  ACTIVE: {
    label: 'Ativo',
    class: 'badge-soft-success'
  },
  INACTIVE: {
    label: 'Inativo',
    class: 'badge-soft-danger'
  }
}

export default {
  setup () {
    const route = useRoute()
    const router = useRouter()
    const term = ref({
      title: '',
      description: '',
      attachment: '',
      status: '',
      created_at: '',
      updated_at: ''
    })

    const onUpload = (file, field) => {
      const images = JSON.parse(file.xhr.responseText)
      // eslint-disable-next-line
      const path = images.map(({ cdn_url }) => cdn_url)[0]
      term.value[field] = path
    }

    const submitTermForm = async () => {
      try {
        if (route.params.id) {
          await updateTerm(route.params.id, term.value)
        } else {
          await createTerm(term.value)
        }
        Notify.create({
          title: 'Dados salvos com sucesso!',
          timer: 1500
        })
        router.push({ name: 'terms_list' })
      } catch (e) {
        Notify.create({
          title: 'Ocorreu um erro ao salvar as informações',
          timer: 1500
        })
      }
    }

    onMounted(async () => {
      term.value.status = 'ACTIVE'
      if (route.params.id) {
        console.log(route.params.id)
        const { data } = await showTerm(route.params.id)
        term.value = data
      }
    })

    return {
      route,
      term,
      statusMap,
      onUpload,
      submitTermForm
    }
  },
  components: {
    FileUploader,
    Layout
  },
  directives: {
    mask
  }
}
</script>

<template>
  <Layout :pagetitle="`${(route.params.id ? 'Editar' : 'Adicionar')} Termo de Uso`">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">
              {{ route.params.id ? 'Editar' : 'Adicionar' }} Termo de Uso
            </h5>
          </div>
          <div class="p-4 border-top">
            <form
              class="was-validated"
              @submit.prevent="submitTermForm"
            >
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label
                      for="title"
                      class="form-label"
                    >
                      Título
                    </label>
                    <input
                      id="title"
                      v-model="term.title"
                      class="form-control"
                      placeholder="Título do termo de uso"
                      required
                    >
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label
                      for="description"
                      class="form-label"
                    >
                      Descrição
                    </label>
                    <textarea
                      id="description"
                      v-model="term.description"
                      class="form-control"
                      placeholder="Descrição"
                      required
                    />
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label
                    class="text-left"
                    for="upload"
                    style="margin-bottom: .5rem; display: block;"
                  >
                    Anexo do termo de uso
                  </label>
                  <div
                    key="attachment"
                    class="mb-3"
                    style="border: 1px dashed #999; margin-top: 1rem; border-radius: 10px;"
                  >
                    <div class="row">
                      <div class="col text-center">
                        <img
                          v-if="!route.params.id || !term.attachment"
                          src="/PDF-Placeholder-240x300.png"
                          class="ms-5 mb-3 mt-3"
                          style="max-height: 100px; max-width: 75%;"
                        >
                        <a
                          v-else
                          :href="term.attachment"
                          target="_blank"
                        >
                          <img
                            class="ms-5 mb-3 mt-3"
                            style="max-height: 100px; max-width: 75%;"
                            src="/pdf-placeholder.png"
                            title="Visualizar o anexo em outra aba"
                          >
                        </a>
                      </div>
                      <div class="col text-center">
                        <file-uploader
                          title="Enviar"
                          class="mt-3"
                          :options="options"
                          :on="{
                            success: (file) => onUpload(file, 'attachment')
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label
                      for="status"
                      class="form-label"
                    >
                      Status
                    </label>
                    <select
                      v-model="term.status"
                      class="form-select"
                      required
                    >
                      <option
                        v-for="(status, key) in statusMap"
                        :key="key"
                        :value="key"
                      >
                        {{ status.label }}
                      </option>
                    </select>
                    <div class="invalid-tooltip">
                      Campo obrigatório!
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap align-items-center justify-content-end">
                <router-link
                  :to="{ name: 'terms_list' }"
                  class="btn btn-secondary me-2"
                >
                  <i class="bx bx-left-arrow-circle me-1" />
                  Cancelar
                </router-link>
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Salvar alterações
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
