import { axios } from '@/axios'

export const getTerms = (params = {}) => {
  return axios.get('/admin/terms', { params }).then(({ data }) => data)
}

export const showTerm = (termId) => {
  return axios.get(`/admin/terms/${termId}`).then(({ data }) => data)
}

export const createTerm = (params = {}) => {
  console.log(params)
  return axios.post('/admin/terms', params).then(({ data }) => data)
}

export const updateTerm = (termId, params = {}) => {
  return axios.put(`/admin/terms/${termId}`, params).then(({ data }) => data)
}
